@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
    margin-left: 270px;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}


@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
  :where(.css-dev-only-do-not-override-w8mnev).ant-col-md-12 {
    max-width: 88% !important;
  }
  .signup_card {
    padding: 0 !important;
  }
  .verifyLeft{
    height: auto;
  }
  .verifyRow{
    height: 100vh;
  }
  .verifyRight{
    border-radius: 0;
  }
}

@media screen and (min-width: 600px){
      .serviesData{
        height: 65%;
      }
}

@media screen and (max-width: 480px) {
  .dashboardHeader {
    flex-direction: column;
  }
  .DespositPoint {
    font-size: 11px;
  }
  .DespositPointBtn {
    font-size: 11px;
    padding: 0 4px;
  }
  .step2cards {
    display: flex;
    padding: 8px 7px;
    justify-content: space-around;
  }
  .step2cardsImg {
    width: 70px;
    margin: 0 !important;
  }
  .step2cardstext {
    display: flex;
    gap: 6px;
    margin: 0 !important;
    align-items: center;
    width: 50%;
    justify-content: center;
    margin: 0 !important;
  }
  :where(.css-dev-only-do-not-override-w8mnev).ant-col-md-12 {
    max-width: 75% !important;
  }
  .h-screen {
    height: auto;
  }
  .auth_container {
    overflow-x: hidden;
  }
  .tagline_auth {
    font-size: 18px;
    padding: 7px 11px;
  }
  .auth_container{
    height: auto;
  }
  .auth_left {
    display: none;
  }
  .auth_right {
    gap: 77px;
    background-color: #fff;
  }
  .auth_LoginForm{
    background-color: #2053cf;
    padding: 43px 12px;
  }
  .ant-row{
    justify-content: center;
  }
  .singupContainer{
    background-color: #2053cf;
    padding: 43px 12px;

  }
.verifyText{
  width: 90%;
}
  .verifyRight {
    width: 100%;
  }
}
